<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Suppose you have a buffer that contains
        <number-value :value="acidConc" unit="\text{M}" />
        of a weak acid and
        <number-value :value="baseConc" unit="\text{M}" />
        of its conjugate base. If the
        <stemble-latex content="$\text{pK}_\text{a}$" />
        of the weak acid is
        <number-value :value="pKa" unit="," />
        determine the
        <stemble-latex content="$\text{pH}$" />
        of the buffer solution.
      </p>

      <calculation-input
        v-model="inputs.pH"
        prepend-text="$\text{pH:}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question146',
  components: {
    StembleLatex,
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        pH: null,
      },
    };
  },
  computed: {
    acidConc() {
      return this.taskState.getValueBySymbol('acidConc').content;
    },
    baseConc() {
      return this.taskState.getValueBySymbol('baseConc').content;
    },
    pKa() {
      return this.taskState.getValueBySymbol('pKa').content;
    },
  },
};
</script>
